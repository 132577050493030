<template>
	<div class="">
		<div>
			<el-image style="height: 400px; width: 100%" :src="require('@/assets/images/fy.jpg')"></el-image>
		</div>
		<div class="page-content top">
			<div>
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>翻译润色</el-breadcrumb-item>
					<el-breadcrumb-item>论文翻译</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div style="margin-top: 40px;">
				<p style="color: #333333;font-weight: bold;">◆ 业务简介</p>
				<div style="display: flex;justify-content: space-between;">
					<el-card class="box-card" shadow="hover"
						style="width: 700px;background-color: #eeeeee;padding: 10px 20px 10px 30px;">
						<div>
							<div style="display: flex;">
								<el-image style="margin-right: 40px"
									:src="require('@/assets/images/translation_1.png')"></el-image>
								<div class="carda">
									<el-card shadow="hover"
										style="width: 150px;height: 33px;font-weight: bold;color:#18305b;background-color: #eeeeee;text-align: center;">
										中译英论文翻译</el-card>
								</div>
							</div>
							<div style="display: flex;">
								<div style="width: 48%;color: #7d7d7d;">
									<ul>
										<li>翻译中文稿件</li>
										<li>相近领域留学博士</li>
										<li>本科论文、硕士论文</li>
										<li>博士论文、学术论文</li>
										<li>稿件翻译1000字/天</li>
									</ul>
								</div>
								<div style="margin-right: 15%;">
									<el-divider direction="vertical" style="margin-top: 20px;height: 120px;">
									</el-divider>
								</div>
								<div style="color: #333333;">
									<div><span style="font-weight: bold;">￥</span><span
											style="font-weight: bold;font-size: 55px;">400</span><span>元</span></div>
									<div style="line-height: 7px;">
										<p><span>√</span><span>铂金会员</span><span
												style="color: #ff5722;">￥360</span><span>千/字</span></p>
										<p><span>√</span><span>钻石会员</span><span
												style="color: #ff5722;">￥320</span><span>千/字</span></p>
									</div>
								</div>
							</div>
						</div>
					</el-card>
					<el-card class="box-card" shadow="hover"
						style="width: 700px;background-color: #eeeeee;padding: 10px 20px 10px 30px;">
						<div>
							<div style="display: flex;">
								<el-image style="margin-right: 40px"
									:src="require('@/assets/images/translation_2.png')"></el-image>
								<div class="carda">
									<el-card shadow="hover"
										style="width: 200px;height: 33px;font-weight: bold;color:#18305b;background-color: #eeeeee;text-align: center;">
										中译英论文翻译+润色</el-card>
								</div>
							</div>
							<div style="display: flex;">
								<div style="width: 48%;color: #7d7d7d;">
									<ul>
										<li>翻译中文稿件</li>
										<li>相近领域留学博士</li>
										<li>欧美母语编辑校对</li>
										<li>直接可投SCI期刊和EI期刊</li>
										<li>翻译+润色500字/天</li>
									</ul>
								</div>
								<div style="margin-right: 15%;">
									<el-divider direction="vertical" style="margin-top: 20px;height: 120px;">
									</el-divider>
								</div>
								<div style="color: #333333;">
									<div><span style="font-weight: bold;">￥</span><span
											style="font-weight: bold;font-size: 55px;">880</span><span>元</span></div>
									<div style="line-height: 7px;">
										<p><span>√</span><span>铂金会员</span><span
												style="color: #ff5722;">￥790</span><span>千/字</span></p>
										<p><span>√</span><span>钻石会员</span><span
												style="color: #ff5722;">￥700</span><span>千/字</span></p>
									</div>
								</div>
							</div>
						</div>
					</el-card>
				</div>
				<div style="text-align: center;margin: 20px;">
					<el-button type="danger" style="width: 300px;height: 60px;font-size: 16px;" @click="uploadFile()">
						立即上传文稿<i class="el-icon-upload el-icon-right" style="margin-left: 10px;"></i></el-button>
				</div>
			</div>
			<div class="transkation-bg">
				<p style="color: #333333;font-weight: bold;">◆ 适合稿件</p>
				<p style="text-indent: 100px;">适合中文撰写的本硕博论文或学术论文，留学申请材料及文献资料</p>
				<p style="color: #333333;font-weight: bold;margin-top: 40px;">◆ 编辑内容</p>
				<div style="display: flex;margin-top: 40px;justify-content: space-between;">
					<div>
						<el-image style="" :src="require('@/assets/images/translation_3.png')"></el-image>
						<div style="text-align: center;line-height: 7px;color: #333333;">
							<p style="font-size: 14px;font-weight: bold;">翻译</p>
							<p style="font-size: 12px;">专家博士中译英</p>
						</div>
					</div>
					<div>
						<el-image style="" :src="require('@/assets/images/translation_4.png')"></el-image>
						<div style="text-align: center;line-height: 7px;color: #333333;">
							<p style="font-size: 14px;font-weight: bold;">翻译</p>
							<p style="font-size: 12px;">专家博士中译英</p>
						</div>
					</div>
					<div>
						<el-image style="" :src="require('@/assets/images/translation_5.png')"></el-image>
						<div style="text-align: center;line-height: 7px;color: #333333;">
							<p style="font-size: 14px;font-weight: bold;">翻译</p>
							<p style="font-size: 12px;">专家博士中译英</p>
						</div>
					</div>
					<div>
						<el-image style="" :src="require('@/assets/images/translation_6.png')"></el-image>
						<div style="text-align: center;line-height: 7px;color: #333333;">
							<p style="font-size: 14px;font-weight: bold;">翻译</p>
							<p style="font-size: 12px;">专家博士中译英</p>
						</div>
					</div>
					<div>
						<el-image style="" :src="require('@/assets/images/translation_7.png')"></el-image>
						<div style="text-align: center;line-height: 7px;color: #333333;">
							<p style="font-size: 14px;font-weight: bold;">翻译</p>
							<p style="font-size: 12px;">专家博士中译英</p>
						</div>
					</div>
				</div>
			</div>
			<div style="margin-top: 100px;">
				<p style="color: #333333;font-weight: bold;">◆ 业务费用</p>
				<div style="display: flex;">
					<div style="width: 57%;margin-right: 2%;">
						<el-table :data="tableData" border style="width: 100%" :header-cell-style="headClass">
							<el-table-column prop="name" label="业务名称" />
							<el-table-column prop="vip0" label="非会员（元/千字）" />
							<el-table-column prop="vip1" label="铂金会员（元/千字）" />
							<el-table-column prop="vip2" label="钻石会员（元/千字）" />
						</el-table>
					</div>
					<div style="line-height: 7px;padding-top: 40px;">
						<p style="color: #c00000;font-weight: 700;">* 协会老客户均可享受九折优惠</p>
						<p>- 官网累计消费或预存满5千元可升级为铂金会员</p>
						<p>- 官网累计消费或预存满1万元可升级为钻石会员</p>
						<p>- 可开具正规国内翻译润色发票、支持预存款、支持对公转账</p>
					</div>
				</div>
			</div>
			<div style="margin-top: 100px;">
				<p style="color: #333333;font-weight: bold;">◆ 售后条款</p>
				<div>
					<div>
						<el-table :data="tableData2" border style="width: 100%" :header-cell-style="headClass">
							<el-table-column prop="name" label="业务名称" />
							<el-table-column prop="name2" label="无偿返修条件" />
						</el-table>
					</div>

				</div>
			</div>
			<div style="margin-top: 100px;">
				<p style="color: #333333;font-weight: bold;">◆ 服务流程</p>
				<div style="display: flex;margin-top: 40px;justify-content: space-between;">
					<div style="">
						<el-image style="" :src="require('@/assets/images/service_1.png')"></el-image>
						<div style="text-align: center;line-height: 7px;">
							<p style="font-size: 14px;font-weight: bold;">STEP1</p>
							<p style="font-size: 12px;">提交稿件并说明需求</p>
						</div>
					</div>
					<div style="margin-top: 35px;">
						<el-icon color="#ff5722" :size="40">
							<ArrowRightBold />
						</el-icon>
					</div>
					<div style="">
						<el-image style="" :src="require('@/assets/images/service_2.png')"></el-image>
						<div style="text-align: center;line-height: 7px;">
							<p style="font-size: 14px;font-weight: bold;">STEP2</p>
							<p style="font-size: 12px;">确认订单并付款</p>
						</div>
					</div>
					<div style="margin-top: 35px;">
						<el-icon color="#ff5722" :size="40">
							<ArrowRightBold />
						</el-icon>
					</div>
					<div style="">
						<el-image style="" :src="require('@/assets/images/service_3.png')"></el-image>
						<div style="text-align: center;line-height: 7px;">
							<p style="font-size: 14px;font-weight: bold;">STEP3</p>
							<p style="font-size: 12px;">根据稿件匹配编辑</p>
						</div>
					</div>
					<div style="margin-top: 35px;">
						<el-icon color="#ff5722" :size="40">
							<ArrowRightBold />
						</el-icon>
					</div>
					<div style="">
						<el-image style="" :src="require('@/assets/images/service_4.png')"></el-image>
						<div style="text-align: center;line-height: 7px;">
							<p style="font-size: 14px;font-weight: bold;">STEP4</p>
							<p style="font-size: 12px;">时间内交付稿件</p>
						</div>
					</div>
					<div style="margin-top: 35px;">
						<el-icon color="#ff5722" :size="40">
							<ArrowRightBold />
						</el-icon>
					</div>
					<div style="">
						<el-image style="" :src="require('@/assets/images/service_5.png')"></el-image>
						<div style="text-align: center;line-height: 7px;">
							<p style="font-size: 14px;font-weight: bold;">STEP5</p>
							<p style="font-size: 12px;">开具发票和售后服务</p>
						</div>
					</div>
				</div>
			</div>
			<div style="margin-top: 100px;">
				<p style="color: #333333;font-weight: bold;">◆ 保密协议</p>
				<p style="text-indent: 100px;">
					IAMSET尊重知识产权，每一位IAMSET工作人员都经历过严格系统的职业道德培训以及签订了具有法律效应的《保密协议》。文件数据传输过程中经历多个安全层，确保文章信息不泄露。</p>
			</div>
		</div>
	</div>

	<el-dialog title="立即上传文稿" v-model="dialogFormVisible" class="loading">
		<div class="journalDetails2-1">
			<p style="padding: 10px">
				<span>适合中文撰写的本硕博论文或学术论文，留学申请材料及文献资料</span>
			</p>
		</div>
		<div class="journalDetails2-2 loading1">
			<el-form :model="releaseForm" :rules="rules" ref="releaseForm">
				<el-form-item label="论文标题" label-width="100px" required prop="title">
					<el-input v-model="releaseForm.title" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="联系人" label-width="100px" required prop="authorName">
					<el-input v-model="releaseForm.authorName" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="联系电话" label-width="100px" required prop="phone">
					<el-input v-model="releaseForm.phone" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="联系邮箱" label-width="100px" required prop="eMail">
					<el-input v-model="releaseForm.eMail" autocomplete="off"></el-input>
				</el-form-item>
				<br />
				<el-form-item style="width: 60%">
					<el-upload class="upload-demo" ref="upload" action="http://47.97.203.109:8066/upload/file"
						:on-remove="handleRemove" :on-success="onSuccess" multiple :limit="1" :on-exceed="handleExceed"
						:file-list="fileList">
						<el-button size="medium" type="info">上传附件</el-button>
						<template #tip>
							<div class="el-upload__tip" style="color: red">
								注：文档支持.zip .rar .doc .docx .txt .pdf等格式
							</div>
						</template>
					</el-upload>
				</el-form-item>
				<el-form-item style="text-align: center; width: 100%;">
					<el-button type="primary" @click="onSubmit('releaseForm')"
						style="background-color: #1b5faa; width: 282px">立即提交</el-button>
				</el-form-item>
			</el-form>
		</div>
	</el-dialog>

</template>
<script>
	import $http from "@/request/http";
	import {
		Edit,
		DocumentCopy,
		ArrowRightBold
	} from "@element-plus/icons"
	export default {
		components: {
			Edit,
			DocumentCopy,
			ArrowRightBold
		},
		data() {
			return {
				dialogFormVisible: false,
				fileList: [],
				headClass() {
					return "text-align: center;background:#7d7d7d;color:white";
				},
				tableData: [{
						name: "论文翻译（中译英）",
						vip0: "400",
						vip1: "360",
						vip2: "320"
					},
					{
						name: "论文翻译+英文润色",
						vip0: "880",
						vip1: "790",
						vip2: "700"
					},
				],
				tableData2: [{
						name: "中文论文翻译",
						name2: "如果作者对某处翻译觉得有不妥之处，12个月内免费返修一次"
					},
					{
						name: "论文翻译+英文润色",
						name2: "稿件润色后国际期刊仍要求改进英文语言方面，未经修改的稿件，12月内无偿返修，修改部分超过20%，修改部分按照原价7折收取费用。"
					},
				],
				releaseForm: {
					authorName: "",
					eMail: "",
					phone: "",
					title: "",
					spareFieldO: "",
					spareFieldT: "fy"
				},
				rules: {
					authorName: [{
						required: true,
						trigger: "blur",
						message: "请输入联系人"
					}, ],

					eMail: [{
						required: true,
						trigger: "blur",
						message: "请输入联系人邮箱"
					}, ],
					phone: [{
						required: true,
						trigger: "blur",
						message: "请输入联系电话"
					}, ],
					title: [{
						required: true,
						trigger: "blur",
						message: "请输入论文标题"
					}, ],

				},
			};
		},

		methods: {
			handleExceed(files, fileList) {
				this.$message.warning(
					`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
					 files.length + fileList.length
					} 个文件`
				);
			},
			onSuccess(esponse, file, fileList) {
				this.fileList = fileList;
				this.releaseForm.spareFieldO = this.fileList[0].response.data
			},
			handleRemove(file, fileList) {
				this.fileList = fileList;
				this.releaseForm.spareFieldO = null
			},
			onSubmit(releaseForm) {
				this.$refs[releaseForm].validate((valid) => {
					if (valid) {
						let _this = this;
						$http
							.post("addOnlineSubmission", this.releaseForm, "loading")
							.then(function(res) {
								console.log(res)
								if (res.code == 200) {
									_this.$message.success({
										showClose: true,
										duration: 1500,
										message: res.msg,
										type: "success",
										onClose: function() {
											_this.dialogFormVisible = false
											_this.$refs[releaseForm].resetFields();
											_this.$refs. ['upload'].clearFiles();
										},
									});
								}

							})
							.catch(function() {});
					} else {
						//   this.$message.error("请检查必填项！");
						this.$notify.error({
							title: "错误",
							message: "请检查必填项！",
						});
						return false;
					}
				});
			},
			uploadFile() {
				this.dialogFormVisible = true
			}
		},

	};
</script>

<style lang="scss" scoped>
	.el-button--danger {
		color: #fff;
		background-color: #ff5722;
		border-color: #ff5722;
	}

	.el-card {
		--el-card-border-color: #18305b;
	}

	.carda {
		.el-card {
			--el-card-padding: 6px;
		}
	}

	.transkation-bg {
		margin-top: 40px;
		padding: 50px;
		background-image: url("../../assets/images/translation-bg.png");
	}
</style>
